<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <div class="card-title pt-3 px-3 d-flex justify-content-between">
            <div class="breadcrumb-left">
              <h4>
                <h4>
                  <router-link :to="{ name: 'download' }">
                    <i class="fas fa-arrow-left"></i>
                  </router-link>
                  &nbsp; Download categories
                </h4>
              </h4>
              <div class="breadcrumb-sub-header">
                <router-link to="/dashboard">Dashboard </router-link>\ Categories
              </div>
            </div>
            <div class="breadcrumb-right">
              <v-btn @click="addType()" class="mt-4 btn btn-primary mr-1" dark>
                <i class="fa fa-plus"></i>
                Add category
              </v-btn>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="">
            <v-skeleton-loader type="table-thead" v-if="loading"> </v-skeleton-loader>

            <v-skeleton-loader
              v-if="loading"
              type="table-row-divider@25"
            ></v-skeleton-loader>
            <table class="table">
              <thead>
                <tr class="px-3">
                  <th class="px-3 wrap-column">Title</th>
                  <th class="px-3 wrap-column">Status</th>
                  <th class="px-3 wrap-column">Action</th>
                </tr>
              </thead>
              <tbody v-if="types.length > 0">
                <tr v-for="(type, index) of types" :key="index">
                  <td class="px-3 wrap-column">{{ type.title }}</td>
                  <td class="px-3 pt-3 wrap-column">
                    <span
                      class="badge text-lg`"
                      v-bind:class="{
                        'badge-success': type.is_active,
                        'badge-danger': !type.is_active,
                      }"
                      >{{ type.is_active ? "Active" : "Inactive" }}</span
                    >
                  </td>
                  <td class="px-3 wrap-column">
                    <b-dropdown
                      size="sm"
                      variant="link"
                      toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                      no-caret
                      right
                      no-flip
                    >
                      <template v-slot:button-content>
                        <slot>
                          <span>
                            <i class="flaticon-more-1"></i> </span
                        ></slot>
                      </template>
                      <!--begin::Navigation-->
                      <div class="navi navi-hover">
                        <b-dropdown-text tag="div" class="navi-item">
                          <a href="#" class="navi-link" @click="editDownloadType(type)">
                            <span class="navi-icon">
                              <i class="flaticon-edit"></i>
                            </span>
                            <span class="navi-text"> Edit </span>
                          </a>
                        </b-dropdown-text>

                        <b-dropdown-text tag="div" class="navi-item">
                          <a
                            href="#"
                            class="navi-link"
                            @click="deleteDpwnloadType(type.id)"
                          >
                            <span class="navi-icon">
                              <i class="fas fa-trash"></i>
                            </span>
                            <span class="navi-text"> Delete</span>
                          </a>
                        </b-dropdown-text>
                      </div>
                      <!--end::Navigation-->
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <td></td>
                <td></td>
                <td>Data not available</td>
              </tbody>
            </table>
          </div>

          <div class="row">
            <div class="col-12"></div>
          </div>
        </div>
      </div>
    </div>
    <create-and-update ref="download-type" @refresh_list="getTypes"></create-and-update>
  </div>
</template>
<script>
import DownloadTypeService from "@/core/services/download/type/DownloadTypeService";
import CreateAndUpdate from "./CreateAndUpdate";

const typeService = new DownloadTypeService();

export default {
  components: {
    CreateAndUpdate,
  },
  data() {
    return {
      types: [],
      loading: false,
    };
  },
  mounted() {
    this.getTypes();
  },
  methods: {
    getTypes() {
      this.loading = true;
      typeService.paginate().then((response) => {
        this.types = response.data;
        this.loading = false;
      });
    },
    addType() {
      this.$refs["download-type"].createCategory();
    },
    editDownloadType(type) {
      this.$refs["download-type"].showModal(type.id);
    },
    deleteDpwnloadType(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: (confirm) => {
          if (confirm) {
            typeService.delete(id).then((response) => {
              this.types = response.data;
              this.getTypes();
            });
          }
        },
      });
    },
  },
};
</script>
