<template>
  <div>
    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-toolbar dark>
          <v-card-title class="headline">
            {{ `${edit ? "Update" : "Add"} download type` }}
            <hr />
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon @click="cancelForm">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <div class="row">
            <div class="col-12 form-group">
              <v-text-field
                outlined
                dense
                label="Title"
                v-model="type.title"
              ></v-text-field>
              <span class="text-danger" v-if="$v.type.title.$error"
                >This information is required</span
              >
            </div>
            <div class="col-12 form-group">
              <v-switch
                v-model="type.is_active"
                name="check-button"
                label="Status"
              ></v-switch>
            </div>
          </div>

          <div class="row">
            <div class="col-12 text-right">
              <v-btn 
                depressed 
                @click="cancelForm" 
                class="text-gray btn btn-standard
              ">
                  Cancel
              </v-btn>
              
              <v-btn
                class="text-white ml-2 btn btn-primary"
                depressed
                @click="createOrUpdate"
                :loading="isBusy"
                >Save
              </v-btn>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <b-modal
      id="modal-scrollable"
      size="md"
      ref="my-modal"
      hide-footer
      :title="`${edit ? 'Update' : 'Add'} download type`"
    >
      <div class="row">
        <div class="col-12 form-group">
          <v-text-field outlined dense label="Title" v-model="type.title"></v-text-field>
          <span class="text-danger" v-if="$v.type.title.$error"
            >This information is required</span
          >
        </div>
        <div class="col-12 form-group">
          <b-form-checkbox v-model="type.is_active" name="check-button" class switch
            >Status</b-form-checkbox
          >
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-right">
          <v-btn
            class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
            depressed
            @click="hideModal"
            >Cancel
          </v-btn>
          <v-btn
            class="text-primary btn btn-primary btn font-weight-bold px-9 py-4 my-3 font-size-3 mx-4"
            depressed
            @click="createOrUpdate"
            :loading="isBusy"
            >Save
          </v-btn>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";

import DownloadTypeService from "@/core/services/download/type/DownloadTypeService";
const typeService = new DownloadTypeService();

export default {
  validations: {
    type: {
      title: { required },
    },
  },
  data() {
    return {
      dialog: false,
      edit: false,
      isBusy: false,
      type: {
        title: null,
        is_active: true,
      },
    };
  },
  mounted() {},
  methods: {
    cancelForm(){
      this.resetForm()
      this.hideModal()
    },
    showModal(typeId = null) {
      this.dialog = true;
      if (typeId) {
        this.edit = true;
        this.getType(typeId);
      }else{
        this.resetForm()
      }
    },
    createCategory() {
      this.dialog = true;
      this.edit = false;
      this.resetForm();
    },
    hideModal() {
      this.dialog = false;
    },
    toggleModal() {
      this.dialog = !this.dialog;
    },
    getType(id) {
      typeService.show(id).then((response) => {
        this.type = response.data.type;
      });
    },
    createOrUpdate() {
      this.$v.type.$touch();
      if (this.$v.type.$error) {
        setTimeout(() => {
          this.$v.type.$reset();
        }, 3000);
      } else {
        if (this.edit) {
          this.updateType();
        } else {
          this.createType();
        }
      }
    },
    createType() {
      this.isBusy = true;
      typeService.store(this.type).then((response) => {
        this.resetForm();
        this.$emit("refresh_list");
        this.resetForm()
        this.hideModal();
        this.isBusy = false;
      });
    },
    updateType() {
      typeService.update(this.type.id, this.type).then((response) => {
        this.resetForm();
        this.$emit("refresh_list");
        this.hideModal();
      });
    },
    resetForm() {
      this.edit = false;
      this.type = {
        title: null,
        is_active: true,
      };

      this.$v.type.$reset();
    },
  },
};
</script>
