import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class DownloadTypeService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/download-type`;
      }

      paginate() {
            let url = `${this.#api}`;
            return apiService.get(url);
      }

         
      all() {
            let url = `${this.#api}`;
            return apiService.get(url);
      }

      allActive(){
            let url = `${this.#api}-active`;
            return apiService.get(url);
      }

      update(id, data) {
            let url = `${this.#api}/${id}`
            return apiService.put(url, data);

      }

      store(data) {
            let url = `${this.#api}`
            return apiService.post(url, data);

      }

      show(id) {
            let url = `${this.#api}/${id}`
            return apiService.get(url);
      }

      delete(id) {
            let url = `${this.#api}/${id}`
            return apiService.delete(url);
      }

}
